export const profileKeys = {
  profile: ['profile'] as const,
  profileInfo: (id: string) => ['profileInfo', id] as const,
  profileFavourites: () => ['profileFavourites'] as const,
  profilePortfolio: (userId: string) => ['profilePortfolio', userId],
  profileUpdates: () => [...profileKeys.profile, 'updates'],
  getArtists: () => [...profileKeys.profile, 'getArtists'],
  transactions: ['transactions'] as const,
  transactionsInfinity: ['transactionsInfinity'] as const,
} as const;
