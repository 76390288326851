import { useUnit } from 'effector-react';

import { $playerState, $trackDurationSec, $pending, $error } from '../model';

export const usePlayerState = () => {
  const [playerState, trackDurationSec, pending, error] = useUnit([
    $playerState,
    $trackDurationSec,
    $pending,
    $error,
  ]);

  return {
    trackDurationSec,
    playerState,
    pending,
    error,
  };
};
