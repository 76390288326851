import { LIMIT, OFFSET } from '@/entities/constants';

export const productsKeys = {
  all: ['products'] as const,
  getProducts: ({ limit, offset }: { limit: number; offset: number }) => [
    ...productsKeys.all,
    'getProducts',
    offset,
    limit,
  ],
  getProductsAll: ({ limit, offset }: { limit: number; offset: number }) => [
    ...productsKeys.all,
    'getProductsAll',
    offset,
    limit,
  ],
  getProductById: (productId: string) => [
    ...productsKeys.all,
    'getProductById',
    productId,
  ],
  getFeatured: () => [...productsKeys.all, 'getFeatured'],
  postProduct: () => [
    ...productsKeys.all,
    productsKeys.getProducts({ limit: LIMIT, offset: OFFSET }),
    'postProduct',
  ],
} as const;
