import {
  ButtonProps,
  Button as ChakraButton,
  Spinner,
  forwardRef,
} from '@chakra-ui/react';

export const Button = forwardRef<ButtonProps, 'button'>((props, ref) => (
  <ChakraButton
    ref={ref}
    {...props}
    spinner={
      <Spinner
        w='20px'
        h='20px'
        thickness='1.4px'
      />
    }
  />
));

export default Button;
