import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Text,
} from '@chakra-ui/react';
import { FC, PropsWithChildren, ReactElement } from 'react';
import { IconButton } from '../../atoms/icon-button';
import { ShareDetails } from './types';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  shareDetails: ShareDetails;
  copyIcon: ReactElement;
};

export const DrawerShare: FC<PropsWithChildren<Props>> = ({
  onClose,
  isOpen,
  shareDetails,
  copyIcon,
  children,
}) => {
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareDetails.url);
    onClose();
  };

  return (
    <Drawer
      placement='bottom'
      onClose={onClose}
      isOpen={isOpen}
    >
      <DrawerOverlay />
      <DrawerContent
        bg='background.bg-primary'
        borderTopRadius='13px'
      >
        <DrawerHeader
          borderBottomWidth='0.5px'
          borderColor='states.text&icon.disabled'
          p='20px'
        >
          <Flex
            gap='10px'
            align='center'
          >
            <IconButton
              onClick={handleCopyLink}
              variant='outline'
              aria-label='copy'
              color='black&white.white'
              _focus={{
                bg: 'transparent',
                color: 'black&white.white',
              }}
              icon={copyIcon}
              rounded='5px'
            />

            <Flex direction='column'>
              <Text
                as='h4'
                textStyle='text-medium-xs'
              >
                {shareDetails.title}
              </Text>
              <Text
                noOfLines={1}
                maxW={100}
                textStyle='one-line-text-medium-xs'
              >
                {shareDetails.url}
              </Text>
            </Flex>
          </Flex>
        </DrawerHeader>
        <DrawerBody p='20px'>
          <HStack
            gap='8px'
            wrap='wrap'
          >
            {children}
          </HStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
