import { useUnit } from 'effector-react';

import { $playerData, setPlayerData } from '../model';

export const usePlayerData = () => {
  const [data, updateData] = useUnit([$playerData, setPlayerData]);

  return {
    data,
    updateData,
  };
};
