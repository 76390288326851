import { AlertStatus } from '@chakra-ui/alert';
import { ToastPosition, useToast } from '@chakra-ui/react';
import { Alert } from '@ui-kit/atoms/alert';
import { ReactNode, useCallback } from 'react';

type Props = Partial<{
  position: ToastPosition;
  description?: string | ReactNode;
  status: AlertStatus;
}>;

export const useAlert = ({ position, description, status }: Props) => {
  const toast = useToast();
  return useCallback(
    (funcProps?: { description?: string | ReactNode; icon?: ReactNode }) => {
      toast({
        position: position ?? 'top',
        containerStyle: {
          width: '100%',
          maxWidth: '500px',
          paddingInline: '20px',
        },
        render: () => (
          <Alert
            status={status}
            description={funcProps?.description || description || ''}
            icon={funcProps?.icon}
          />
        ),
      });
    },
    [description, position, status, toast],
  );
};
