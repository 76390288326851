import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { iconSizes } from './constants';
import { IconPropsWithSize } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M7.95005 4.6998C6.70752 4.6998 5.70909 5.09647 4.90462 5.87585C4.10145 6.65398 3.70005 7.61079 3.70005 8.79544C3.70005 9.38088 3.80153 9.95395 4.00666 10.5181C4.20699 11.069 4.57256 11.7072 5.13055 12.4349C5.68784 13.1617 6.44967 13.9966 7.42462 14.9412C8.40283 15.8889 9.64542 17.0209 11.1547 18.3384L12 19.0726L12.8447 18.3389C14.354 17.0215 15.5973 15.8889 16.5755 14.9412C17.5504 13.9966 18.3123 13.1617 18.8696 12.4349C19.4275 11.7072 19.7931 11.069 19.9934 10.5181C20.1986 9.95395 20.3 9.38088 20.3 8.79544C20.3 7.61079 19.8987 6.65398 19.0955 5.87585C18.291 5.09647 17.2926 4.6998 16.05 4.6998C15.369 4.6998 14.7251 4.83868 14.1117 5.11688C13.4972 5.39553 12.9705 5.78612 12.5262 6.29252L12 6.89226L11.4739 6.29252C11.0296 5.78612 10.5029 5.39553 9.88844 5.11688C9.27497 4.83868 8.63111 4.6998 7.95005 4.6998ZM3.93047 4.87035C5.01601 3.81867 6.37258 3.2998 7.95005 3.2998C8.82898 3.2998 9.67012 3.48064 10.4667 3.84186C11.0327 4.09856 11.5445 4.43102 12 4.83785C12.4556 4.43102 12.9674 4.09856 13.5334 3.84186C14.33 3.48064 15.1711 3.2998 16.05 3.2998C17.6275 3.2998 18.9841 3.81867 20.0696 4.87035C21.1564 5.92328 21.7 7.24804 21.7 8.79544C21.7 9.54697 21.569 10.2818 21.3092 10.9965C21.0445 11.7244 20.5926 12.4886 19.9805 13.2868C19.3678 14.0859 18.5547 14.973 17.5496 15.9467C16.548 16.9171 15.2858 18.0665 13.7654 19.3936L13.7641 19.3948L12 20.927L10.236 19.3948L10.2347 19.3936C8.71432 18.0665 7.45212 16.9171 6.45047 15.9467C5.44543 14.973 4.63226 14.0859 4.01955 13.2868C3.40753 12.4886 2.95561 11.7244 2.69094 10.9965C2.43107 10.2818 2.30005 9.54698 2.30005 8.79544C2.30005 7.24804 2.84365 5.92328 3.93047 4.87035Z'
      fill='currentColor'
    />
  ),
});

const IconHeartOutline: FC<IconPropsWithSize> = ({ size = 'lg', ...props }) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconHeartOutline);
