import { Sizes } from './types';

export const iconSizes: Sizes = {
  lg: {
    w: '24px',
    h: '24px',
  },
  sm: {
    w: '16px',
    h: '16px',
  },
};
