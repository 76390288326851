import { ReactNode, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useUnit } from 'effector-react';

import {
  setPlayerState,
  setTrackCurrentTimeSec,
  setTrackDurationSec,
  setPending,
  setError,
  $isNextTrack,
  nextTrack,
} from './model';

import { PlayerContext, PlayerContextState } from './player-context';
import { MiniPlayerConnector } from './ui/mini-player-connector';
import { FullscreenPlayerConnector } from './ui/fullscreen-player-connector';
import { useTranslations } from 'next-intl';

type Props = {
  children: ReactNode;
};

export const PlayerProvider = ({ children }: Props) => {
  const t = useTranslations();

  const [
    onPlayerState,
    onTrackCurrentTimeSec,
    onTrackDurationSec,
    onError,
    isNextTrack,
    toNext,
  ] = useUnit([
    setPlayerState,
    setTrackCurrentTimeSec,
    setTrackDurationSec,
    setError,
    $isNextTrack,
    nextTrack,
  ]);

  const audioEl = useRef<HTMLAudioElement | null>(null);

  const [state, setState] = useState<PlayerContextState>({
    audioElement: null,
  });

  useLayoutEffect(() => {
    const element = audioEl.current;
    if (!element) {
      return;
    }
    setState((prevState) => ({ ...prevState, audioElement: element }));

    element.addEventListener('play', () => {
      onPlayerState('playing');
    });

    element.addEventListener('pause', () => {
      onPlayerState('pause');
    });

    element.addEventListener('ended', () => {
      if (isNextTrack) {
        toNext();
        onPlayerState('playing');
      }
    });

    element.addEventListener('waiting', () => {
      setPending(true);
    });

    element.addEventListener('canplaythrough', () => {
      setPending(false);
      onError(false);
    });

    element.addEventListener('canplay', () => {
      onError(false);
    });

    element.addEventListener('timeupdate', () => {
      onTrackCurrentTimeSec(element.currentTime);
    });

    element.addEventListener('loadedmetadata', () => {
      onTrackDurationSec(element.duration || 0);
    });

    element.addEventListener('error', () => {
      onError(true);
    });
  }, [
    isNextTrack,
    onError,
    onPlayerState,
    onTrackCurrentTimeSec,
    onTrackDurationSec,
    toNext,
  ]);

  const contextValue = useMemo(() => ({ state }), [state]);

  return (
    <>
      <audio
        autoPlay={false}
        controls={false}
        loop={false}
        ref={audioEl}
      >
        <track kind='captions' />
        {t('PlayerProvider.yourDeviceDoesNotSupportAudio')}
      </audio>
      <PlayerContext.Provider value={contextValue}>
        <MiniPlayerConnector />
        <FullscreenPlayerConnector />
        {children}
      </PlayerContext.Provider>
    </>
  );
};
