import { PlayerTimelineSlider } from '@/features/audio-player/ui/player-timeline/player-timeline-slider';
import { useIsMobile } from '@/shared/ui-kit/hooks/useIsMobile';
import { toTime } from '@/shared/utils/toTime';
import { Box, Flex, Text } from '@chakra-ui/react';

type Props = {
  currentTimeSec?: number;
  durationSec?: number;
};
export const PlayerTimeline = ({
  currentTimeSec = 0,
  durationSec = 0,
}: Props) => {
  const trackPercent = durationSec ? (currentTimeSec * 100) / durationSec : 0;
  const isMobile = useIsMobile();
  return (
    <Box
      maxW={{ base: '317px', lg: '420px' }}
      w='100%'
      marginInline='auto'
    >
      {isMobile && <PlayerTimelineSlider trackPercent={trackPercent} />}
      <Flex
        mt={{ base: 1, lg: 2.5 }}
        gap={{ base: 0, lg: 2.5 }}
        alignItems='center'
        justifyContent='space-between'
      >
        <Text
          width='30px'
          color='grey.500'
          textStyle='text-regular-xxs'
        >
          {toTime(currentTimeSec)}
        </Text>
        {!isMobile && (
          <PlayerTimelineSlider
            trackPercent={trackPercent}
            trackBg='grey.200'
            filledTrackBg='grey.970'
          />
        )}
        <Text
          width='30px'
          color='grey.500'
          textStyle='text-regular-xxs'
        >
          {toTime(durationSec)}
        </Text>
      </Flex>
    </Box>
  );
};
