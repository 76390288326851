import IconCheck2 from '@/shared/ui-kit/icons/icon-check2';
import { DrawerShare } from '@/shared/ui-kit/organisms/drawer-share';
import { ShareDetails } from '@/shared/ui-kit/organisms/drawer-share/types';
import { FC } from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  shareDetails: ShareDetails;
};

export const DemuzoDrawerShare: FC<Props> = ({
  shareDetails,
  onClose,
  isOpen,
}) => {
  return (
    <DrawerShare
      shareDetails={shareDetails}
      onClose={onClose}
      isOpen={isOpen}
      copyIcon={<IconCheck2 />}
    >
      <FacebookShareButton url={shareDetails.url}>
        <FacebookIcon
          size={60}
          borderRadius={32}
        />
      </FacebookShareButton>
      <WhatsappShareButton url={shareDetails.url}>
        <WhatsappIcon
          size={60}
          borderRadius={32}
        />
      </WhatsappShareButton>
      <TelegramShareButton url={shareDetails.url}>
        <TelegramIcon
          size={60}
          borderRadius={32}
        />
      </TelegramShareButton>
      <TwitterShareButton url={shareDetails.url}>
        <TwitterIcon
          size={60}
          borderRadius={32}
        />
      </TwitterShareButton>
      <LinkedinShareButton url={shareDetails.url}>
        <LinkedinIcon
          size={60}
          borderRadius={32}
        />
      </LinkedinShareButton>
    </DrawerShare>
  );
};
