import { usePlayerState, useTrackTime } from '../hooks';
import { PlayerTimeline } from './player-timeline/player-timeline';

export const TimeLineConnector = () => {
  const { trackDurationSec } = usePlayerState();
  const { trackCurrentTimeSec } = useTrackTime();
  return (
    <PlayerTimeline
      currentTimeSec={trackCurrentTimeSec}
      durationSec={trackDurationSec}
    />
  );
};
