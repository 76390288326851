import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { iconSizes } from './constants';
import { IconPropsWithSize } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: [
    <path
      key='path-1'
      d='M8.38457 9.91498C6.77453 9.91498 5.46934 11.2202 5.46934 12.8302V17.0001C5.46934 18.8227 6.9468 20.3001 8.76934 20.3001H15.2303C17.0528 20.3001 18.5303 18.8227 18.5303 17.0001V12.8302C18.5303 11.2202 17.2251 9.91498 15.615 9.91498V8.51498C17.9983 8.51498 19.9303 10.447 19.9303 12.8302V17.0001C19.9303 19.5959 17.826 21.7001 15.2303 21.7001H8.76934C6.1736 21.7001 4.06934 19.5959 4.06934 17.0001V12.8302C4.06934 10.447 6.00133 8.51498 8.38457 8.51498V9.91498Z'
      fill='currentColor'
    />,
    <path
      key='path-2'
      d='M7.0154 5.35962L11.9998 0.581543L16.9842 5.35962L16.0154 6.37027L12.6998 3.19192L12.6998 12.7936H11.2998V3.19192L7.98421 6.37027L7.0154 5.35962Z'
      fill='currentColor'
    />,
  ],
});

const IconShare2: FC<IconPropsWithSize> = ({ size = 'lg', ...props }) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconShare2);
