import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';

export const Divider: FC<BoxProps> = (props) => {
  return (
    <Box
      w='full'
      h='1px'
      bgGradient='repeating-linear(to-r, stroke.st-secondary, 2px, 
transparent 2px, transparent 6px)'
      {...props}
    />
  );
};

export default Divider;
