import { useUnit } from 'effector-react';
import { $playlist, setPlayList, $currentTrack } from '../model';

export const usePlayList = () => {
  const [playlist, onSetPlayList, currentTrack] = useUnit([
    $playlist,
    setPlayList,
    $currentTrack,
  ]);

  return {
    playlist,
    currentTrack,
    setPlayList: onSetPlayList,
  };
};
