import { FC } from 'react';
import {
  IconButton as ChakraButton,
  IconButtonProps,
  Spinner,
} from '@chakra-ui/react';

export const IconButton: FC<IconButtonProps> = (props) => {
  return (
    <ChakraButton
      {...props}
      spinner={
        <Spinner
          w='20px'
          h='20px'
          thickness='1.4px'
        />
      }
    />
  );
};

export default IconButton;
