import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { iconSizes } from './constants';
import { IconPropsWithSize } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M11.0097 11.9961L4.43207 18.5738L5.42202 19.5637L11.9997 12.986L18.5777 19.564L19.5676 18.5741L12.9896 11.9961L19.5642 5.42159L18.5742 4.43164L11.9997 11.0061L5.42548 4.43193L4.43553 5.42188L11.0097 11.9961Z'
      fill='currentColor'
    />
  ),
});

const IconClose: FC<IconPropsWithSize> = ({ size = 'lg', ...props }) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconClose);
