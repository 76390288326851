import { useUnit } from 'effector-react';
import { usePlayList, usePlayerControls, usePlayerState } from '../hooks';
import { MiniPlayer } from './mini-player/mini-player';
import { toggleFullscreenPlayer } from '../model';
import { MiniPlayerDefaultBuySlot } from '@/features/audio-player/ui/mini-player/mini-player-default-buy-slot';
import { TimeLineConnector } from '@/features/audio-player/ui/timeline-connector';
import { ControlsConnector } from '@/features/audio-player/ui/controls-connector';
import { usePlayerData } from '@/features/audio-player/hooks/use-player-data';
import hardPush from '@/shared/utils/hard-push';
import { useShare } from '@ui-kit/hooks/useShare';
import { DemuzoDrawerShare } from '@/shared/components/demuzo-drawer-share';
import { DemuzoModalShare } from '@/shared/components/demuzo-modal-share';
import { useIsMobile } from '@ui-kit/hooks/useIsMobile';
import { Text } from '@chakra-ui/react';
import IconHeartFill from '@ui-kit/icons/icon-heart-fill';
import IconHeartBroken from '@ui-kit/icons/icon-heart-broken';
import { useAlert } from '@ui-kit/hooks/useAlert';
import { useDeleteFavorite, usePostFavorite } from '@/entities/user';
import { useTranslations } from 'next-intl';
import { useCallback } from 'react';
import { signIn, useSession } from 'next-auth/react';

const FavoriteAlertDescription = ({
  isAdded = false,
  isSuccess,
}: {
  isSuccess: boolean;
  isAdded: boolean;
}) => {
  const t = useTranslations();
  if (!isSuccess) {
    return (
      <Text textStyle='text-regular-lg'>
        {t.rich('FavoriteAlertDescription.error', {
          kind: isAdded ? 'added' : 'deleted',
          color: (chunks) => (
            <Text
              color='text&icon.tx-accent'
              as='span'
            >
              {chunks}
            </Text>
          ),
        })}
      </Text>
    );
  }

  return (
    <Text textStyle='text-regular-lg'>
      {t.rich('FavoriteAlertDescription.success', {
        kind: isAdded ? 'added' : 'deleted',
        color: (chunks) => (
          <Text
            color='text&icon.tx-accent'
            as='span'
          >
            {chunks}
          </Text>
        ),
      })}
    </Text>
  );
};

const useFavoriteAlert = (status: 'add' | 'delete') => {
  const isAdd = status == 'add';
  const icon = isAdd ? (
    <IconHeartFill color='text&icon.tx-accent' />
  ) : (
    <IconHeartBroken color='text&icon.tx-accent' />
  );
  const showSuccessAlert = useAlert({ status: 'success' });
  const showErrorAlert = useAlert({ status: 'error' });

  return (isSuccess: boolean) => {
    const description = (
      <FavoriteAlertDescription
        isSuccess={isSuccess}
        isAdded={isAdd}
      />
    );

    if (isSuccess) {
      return showSuccessAlert({ description, icon });
    }

    return showErrorAlert({ description, icon });
  };
};

export const MiniPlayerConnector = () => {
  const t = useTranslations();
  const onToggleFullscreenPlayer = useUnit(toggleFullscreenPlayer);
  const { currentTrack } = usePlayList();
  const { play, pause } = usePlayerControls();
  const { pending, playerState } = usePlayerState();
  const { data, updateData } = usePlayerData();
  const {
    handleCloseDrawerShare,
    isOpenDrawerShare,
    shareDetails,
    handleShare,
  } = useShare();
  const handleShareProductLink = () => {
    const currentShareDetails = {
      url: `${process.env.NEXT_PUBLIC_BASE_URL}/project/${data?.currentCampaignId}`,
      title: 'Demuzo',
    };

    handleShare(currentShareDetails);
  };
  const isMobile = useIsMobile();
  const canBuy = data?.canBuy;
  const showAddFavoriteAlert = useFavoriteAlert('add');
  const showDeleteFavoriteAlert = useFavoriteAlert('delete');
  const mutationSubscribe = usePostFavorite(showAddFavoriteAlert);
  const mutationUnSubscribe = useDeleteFavorite(showDeleteFavoriteAlert);
  const session = useSession();

  const handleToggleFavorite = async () => {
    if (!data?.currentCampaignId) {
      return;
    }

    if (!data?.isFavoriteCampaign) {
      mutationSubscribe.mutate(data.currentCampaignId, {
        onSuccess: () => {
          updateData({ isFavoriteCampaign: true });
        },
      });
      return;
    }

    mutationUnSubscribe.mutate(data.currentCampaignId, {
      onSuccess: () => {
        updateData({ isFavoriteCampaign: false });
      },
    });
  };
  const onBuyClick = useCallback(
    async (id: string) => {
      const callbackUrl = `/project/purchase/${id}`;
      if (session.status === 'unauthenticated') {
        await signIn('keycloak', { callbackUrl });
        return;
      }

      hardPush(callbackUrl);
    },
    [session],
  );

  if (!currentTrack?.name) {
    return;
  }

  return (
    <>
      <MiniPlayer
        data={{
          coverUrl: currentTrack?.coverUrl,
          snippetName: currentTrack.name,
          snippetAuthor: currentTrack.authors
            .map((item) => item.name)
            .join(' x '),
          id: currentTrack.id,
        }}
        isLoading={pending}
        onOpenPlayer={() => onToggleFullscreenPlayer(true)}
        playControl={{
          isPlaying: playerState === 'playing',
          onClick: () => (playerState === 'playing' ? pause() : play()),
        }}
        onBuy={() => {
          onBuyClick(data?.currentCampaignId || '');
        }}
        canBuy={canBuy}
        isLiked={data?.isFavoriteCampaign}
        onLike={handleToggleFavorite}
        onShare={handleShareProductLink}
        buySlot={
          canBuy ? (
            <MiniPlayerDefaultBuySlot
              onClick={() => {
                onBuyClick(data?.currentCampaignId || '');
              }}
              text='Buy'
            />
          ) : null
        }
        playControls={
          <>
            <ControlsConnector />
            <TimeLineConnector />
          </>
        }
      />
      {isMobile ? (
        <DemuzoDrawerShare
          shareDetails={shareDetails}
          onClose={handleCloseDrawerShare}
          isOpen={isOpenDrawerShare}
        />
      ) : (
        <DemuzoModalShare
          shareDetails={shareDetails}
          onClose={handleCloseDrawerShare}
          isOpen={isOpenDrawerShare}
        />
      )}
    </>
  );
};
