import {
  Alert as AlertChakra,
  AlertDescription,
  AlertIcon,
  AlertProps,
  Box,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

type Props = {
  description: string | ReactNode;
  icon?: ReactNode;
} & AlertProps;

export const Alert: FC<Props> = ({
  description,
  rounded = '20px',
  m,
  mt,
  margin,
  marginTop,
  icon = <AlertIcon />,
  ...props
}) => {
  return (
    <Box
      rounded={rounded}
      pt='1px'
      pr='1px'
      m={m}
      mt={mt}
      margin={margin}
      marginTop={marginTop}
    >
      <AlertChakra
        {...props}
        overflow='hidden'
      >
        <AlertDescription>{description}</AlertDescription>

        {icon}
      </AlertChakra>
    </Box>
  );
};

export default Alert;
