import { Link, Image } from '@chakra-ui/next-js';
import {
  Flex,
  Text,
  Avatar,
  AvatarGroup as AvatarGroupChakra,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Drawer } from '../drawer';
import { useState } from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useTranslations } from 'next-intl';
import { Divider } from '../../molecules/divider';

type TProps = {
  artists: {
    name: string;
    avatarUrl?: string;
  }[];
  size: 'sm' | 'auto';
  direction?: 'row' | 'column';
};

export const AvatarGroup = ({ artists, size, direction = 'row' }: TProps) => {
  const [isArtistDrawer, setIsArtistDrawer] = useState(false);
  const t = useTranslations();
  const isMobile = useIsMobile();
  return (
    <Flex>
      {isMobile || size === 'sm' ? (
        <Flex
          alignItems='center'
          gap='10px'
          flexWrap='wrap'
          justifyContent='flex-end'
          direction={direction}
        >
          <Flex
            direction='row'
            onClick={(e) => {
              setIsArtistDrawer(true);
              e.preventDefault();
            }}
          >
            <AvatarGroupChakra spacing={-5}>
              {artists.slice(0, 3).map((artist, index) => (
                <Avatar
                  key={index}
                  size='sm'
                  name={artist.name}
                  src={artist.avatarUrl}
                />
              ))}
            </AvatarGroupChakra>
          </Flex>
          {artists.length > 3 && (
            <Text
              textStyle='text-regular-sm'
              color={
                direction === 'row'
                  ? 'text&icon.tx-contrast'
                  : 'text&icon.tx-secondary'
              }
            >
              {t('ProjectHeaderBlock.moreArtists', {
                artists: artists.length - 3,
              })}
            </Text>
          )}
        </Flex>
      ) : (
        <Flex
          direction='column'
          alignItems='center'
          gap='20px'
          onClick={() => setIsArtistDrawer(true)}
        >
          <Flex
            direction='row'
            width='280px'
          >
            <AvatarGroupChakra spacing={-20}>
              {artists.slice(0, 3).map((artist, index) => (
                <Avatar
                  key={index}
                  size='xl'
                  name={artist.name}
                  src={artist.avatarUrl}
                />
              ))}
            </AvatarGroupChakra>
          </Flex>
          {artists.length > 3 && (
            <Text textStyle='text-regular-lg'>
              {t('ProjectHeaderBlock.moreArtists', {
                artists: artists.length - 3,
              })}
            </Text>
          )}
        </Flex>
      )}

      {isMobile ? (
        <Drawer
          onClose={() => setIsArtistDrawer(false)}
          HeaderStartComponent={
            <Link
              prefetch={false}
              href='/'
            >
              <Image
                src='/images/logo-white.svg'
                alt='logo'
                width={104}
                height={25}
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
            </Link>
          }
          withHeader
          isOpen={isArtistDrawer}
          size='full'
        >
          <Flex direction='column'>
            <Flex
              justifyContent='space-between'
              mb='20px'
            >
              <Text textStyle='heading-small-xl'>
                {t('ProjectHeaderBlock.drawerArtist')}
              </Text>
              <Text textStyle='text-medium-lg'>{artists.length}</Text>
            </Flex>
            {artists.map((artist, index) => (
              <Flex
                direction='column'
                key={index}
              >
                <Flex
                  key={index}
                  direction='row'
                  gap='20px'
                  paddingY='20px'
                  alignItems='center'
                >
                  <Avatar
                    key={index}
                    size='sm'
                    name={artist.name}
                    src={artist.avatarUrl}
                  />
                  <Text textStyle='heading-small-lg'>{artist.name}</Text>
                </Flex>
                <Divider />
              </Flex>
            ))}
          </Flex>
        </Drawer>
      ) : (
        <Modal
          onClose={() => setIsArtistDrawer(false)}
          isOpen={isArtistDrawer}
          autoFocus={false}
          isCentered
        >
          <ModalOverlay />
          <ModalContent
            bg='background.bg-primary'
            rounded='30px'
            maxH='540px'
          >
            <ModalHeader py='30px'>
              <Flex
                direction='row'
                justifyContent='space-between'
              >
                <Text textStyle='heading-small-xl'>
                  {t('ProjectHeaderBlock.drawerArtist')}
                </Text>
                <Text textStyle='text-medium-lg'>{artists.length}</Text>
              </Flex>
            </ModalHeader>
            <ModalBody
              color='text&icon.tx-secondary'
              overflowY='auto'
            >
              {artists.map((artist, index) => (
                <Flex
                  key={index}
                  direction='row'
                  gap='20px'
                  paddingY='20px'
                  alignItems='center'
                >
                  <Avatar
                    key={index}
                    size='sm'
                    name={artist.name}
                    src={artist.avatarUrl}
                  />
                  <Text textStyle='heading-small-lg'>{artist.name}</Text>
                </Flex>
              ))}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
};
