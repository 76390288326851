import {} from '@/shared/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { productsKeys } from '@/entities/product/model/query-keys';
import { profileKeys } from '@/entities/profile/model/query-keys';
import { newsApi } from '@/shared/api/config';

export const useDeleteFavorite = (
  showMessage?: (isSuccess: boolean) => void,
) => {
  const query = useQueryClient();

  return useMutation({
    mutationFn: (productId: string) =>
      newsApi.deleteNewsV1UserFavorites({
        productId,
      }),
    onSuccess: () => {
      query.invalidateQueries({ queryKey: productsKeys.all });
      query.invalidateQueries({ queryKey: profileKeys.profileFavourites() });
      showMessage?.(true);
    },
    onError: () => showMessage?.(false),
  });
};
