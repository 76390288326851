import {
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  ResponsiveValue,
  Text,
} from '@chakra-ui/react';
import { FC, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { IconButton } from '../../atoms/icon-button';
import IconClose from '../../icons/icon-close';

type TProps = {
  onClose: () => void;
  isOpen: boolean;
  children: ReactNode;
  title?: string;
  withHeader?: boolean;
  HeaderStartComponent?: ReactNode;
  size?: ResponsiveValue<string>;
};

export const Drawer = ({
  onClose,
  isOpen,
  children,
  title,
  withHeader,
  HeaderStartComponent = null,
  size,
}: TProps) => {
  return (
    <ChakraDrawer
      placement='bottom'
      autoFocus={false}
      onClose={onClose}
      isOpen={isOpen}
      size={size}
    >
      <DrawerOverlay />
      <DrawerContent
        bg='background.bg-primary'
        borderTopRadius='13px'
      >
        {withHeader ? (
          <DrawerHeader
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            mt='20px'
            p='20px'
          >
            {title ? (
              <Text textStyle='heading-small-xl'>{title}</Text>
            ) : (
              HeaderStartComponent
            )}

            <IconButton
              aria-label='close'
              icon={<IconClose />}
              variant='outline'
              colorScheme='white'
              border='none'
              size='xs'
              onClick={onClose}
            />
          </DrawerHeader>
        ) : null}
        <DrawerBody p='20px'>{children}</DrawerBody>
      </DrawerContent>
    </ChakraDrawer>
  );
};
