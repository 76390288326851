import { Slider, SliderFilledTrack, SliderTrack } from '@chakra-ui/react';

type Props = {
  trackPercent: number;
  trackBg?: string;
  filledTrackBg?: string;
};

export const PlayerTimelineSlider = ({
  trackPercent,
  trackBg = 'grey.850',
  filledTrackBg = 'white',
}: Props) => (
  <Slider
    defaultValue={0}
    value={trackPercent}
  >
    <SliderTrack bg={trackBg}>
      <SliderFilledTrack bg={filledTrackBg} />
    </SliderTrack>
  </Slider>
);
