import { useUnit } from 'effector-react';
import { $trackCurrentTimeSec } from '../model';

export const useTrackTime = () => {
  const [trackCurrentTimeSec] = useUnit([$trackCurrentTimeSec]);

  return {
    trackCurrentTimeSec,
  };
};
