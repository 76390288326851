import { usePlayerControls, usePlayerState } from '../hooks';
import { PlayerControls } from './player-controls/player-controls';

export const ControlsConnector = () => {
  const { play, next, pause, previous, isNextTrack, isPreviousTrack } =
    usePlayerControls();
  const { pending, playerState } = usePlayerState();
  return (
    <PlayerControls
      onPlay={play}
      onPause={pause}
      onNext={next}
      onPrevious={previous}
      hasNext={isNextTrack}
      hasPrevious={isPreviousTrack}
      isLoading={pending}
      isPlaying={playerState === 'playing'}
    />
  );
};
