import { useCallback, useMemo, useState } from 'react';
import { ShareDetails } from '../organisms/drawer-share/types';

export const useShare = () => {
  const [isOpenDrawerShare, setIsOpenDrawerShare] = useState(false);
  const [shareDetails, setShareDetails] = useState<ShareDetails>({
    url: '',
    title: '',
  });

  const handleShare = useCallback(async (currentShareDetails: ShareDetails) => {
    if (!navigator.share) {
      console.log('browser does not support');

      setShareDetails(currentShareDetails);
      setIsOpenDrawerShare(true);
      return;
    }

    try {
      await navigator.share(currentShareDetails);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleCloseDrawerShare = () => {
    setIsOpenDrawerShare(false);
  };

  return useMemo(
    () => ({
      handleCloseDrawerShare,
      handleShare,
      shareDetails,
      setShareDetails,
      setIsOpenDrawerShare,
      isOpenDrawerShare,
    }),
    [handleShare, isOpenDrawerShare, shareDetails],
  );
};
