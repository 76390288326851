/**
 * Converts a value from one unit to another.
 */

/**
 * Converts a value from a smaller unit to a larger unit.
 * **E.g., converts cents to dollars.**
 * @param {number} value - The amount in the smaller unit (e.g., cents).
 * @param {number} conversionFactor - The conversion factor (e.g., 100 for cents to dollars). Defaults to 100.
 * @returns {number} The amount in the larger unit (e.g., dollars).
 */
export function convertToLargerUnit(
  value: number,
  conversionFactor: number = 100,
): number {
  return Number((value / conversionFactor).toFixed(2));
}

/**
 * Converts a value from a larger unit to a smaller unit.
 * **E.g., converts dollars to cents.**
 * @param {number} value - The amount in the larger unit (e.g., dollars).
 * @param {number} conversionFactor - The conversion factor (e.g., 100 for dollars to cents). Defaults to 100.
 * @returns {number} The amount in the smaller unit (e.g., cents).
 */
export function convertToSmallerUnit(
  value: number,
  conversionFactor: number = 100,
): number {
  return Math.round(value * conversionFactor);
}
