import { IconButton } from '@/shared/ui-kit/atoms/icon-button';
import IconCheck2 from '@/shared/ui-kit/icons/icon-check2';
import { ShareDetails } from '@/shared/ui-kit/organisms/drawer-share/types';
import {
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  shareDetails: ShareDetails;
};

export const DemuzoModalShare: FC<Props> = ({
  shareDetails,
  onClose,
  isOpen,
}) => {
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareDetails.url);
    onClose();
  };

  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          borderBottomWidth='0.5px'
          borderColor='states.text&icon.disabled'
          p='20px'
        >
          <Flex
            gap='10px'
            align='center'
          >
            <IconButton
              onClick={handleCopyLink}
              variant='outline'
              aria-label='copy'
              color='black&white.white'
              _focus={{
                bg: 'transparent',
                color: 'black&white.white',
              }}
              icon={<IconCheck2 />}
              rounded='5px'
            />

            <Flex direction='column'>
              <Text
                as='h4'
                textStyle='text-medium-xs'
              >
                {shareDetails.title}
              </Text>
              <Text
                noOfLines={1}
                maxW={100}
                textStyle='one-line-text-medium-xs'
              >
                {shareDetails.url}
              </Text>
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody p='20px'>
          <HStack
            gap='8px'
            wrap='wrap'
            justify='space-between'
          >
            <FacebookShareButton url={shareDetails.url}>
              <FacebookIcon
                size={60}
                borderRadius={32}
              />
            </FacebookShareButton>
            <WhatsappShareButton url={shareDetails.url}>
              <WhatsappIcon
                size={60}
                borderRadius={32}
              />
            </WhatsappShareButton>
            <TelegramShareButton url={shareDetails.url}>
              <TelegramIcon
                size={60}
                borderRadius={32}
              />
            </TelegramShareButton>
            <TwitterShareButton url={shareDetails.url}>
              <TwitterIcon
                size={60}
                borderRadius={32}
              />
            </TwitterShareButton>
            <LinkedinShareButton url={shareDetails.url}>
              <LinkedinIcon
                size={60}
                borderRadius={32}
              />
            </LinkedinShareButton>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
