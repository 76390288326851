import Button from '@/shared/ui-kit/atoms/button/button';
import { ButtonProps, Text } from '@chakra-ui/react';

type Props = ButtonProps & {
  text?: string;
};

export const MiniPlayerDefaultBuySlot = ({ text, ...rest }: Props) => {
  return (
    <Button
      h='auto'
      alignSelf='stretch'
      colorScheme='green'
      px='20px'
      {...rest}
    >
      <Text textStyle='heading-large-sm'>{text}</Text>
    </Button>
  );
};
