import { MiniPlayerDesktopProps } from '@/features/audio-player/ui/mini-player/types';
import { Box, Flex, Text } from '@chakra-ui/react';
import Button from '@ui-kit/atoms/button/button';
import IconButton from '@ui-kit/atoms/icon-button/icon-button';
import IconHeartFill from '@ui-kit/icons/icon-heart-fill';
import IconHeartOutline from '@ui-kit/icons/icon-heart-outline';
import IconShare2 from '@ui-kit/icons/icon-share-2';
import { useTranslations } from 'next-intl';

export const MiniPlayerDesktop = ({
  data,
  playControls,
  onBuy,
  onShare,
  onLike,
  canBuy,
  isLiked = false,
}: MiniPlayerDesktopProps) => {
  const t = useTranslations();
  return (
    <Box
      position='fixed'
      bottom='20px'
      left='50%'
      transform={'translateX(-50%)'}
      w='full'
      px={{ base: '20px', lg: '40px' }}
      maxWidth={{
        base: 'full',
        md: 'container.md',
        lg: 'container.lg',
        xl: 'container.xl',
      }}
      zIndex={100}
    >
      <Flex
        bg='#f3f3f3'
        padding={2.5}
        borderRadius={50}
        alignItems='center'
        justifyContent='center'
      >
        <Flex
          flex={1}
          alignItems='center'
          marginRight='auto'
          gap='15px'
        >
          <Box
            boxSize='70px'
            borderRadius={70}
            bgImage={data.coverUrl}
            bgSize='cover'
            bgPos='center'
            bgRepeat='no-repeat'
          />

          <Flex
            direction='column'
            flex={1}
          >
            <Text
              noOfLines={1}
              textStyle='one-line-heading-small-lg'
              color='grey.900'
            >
              {data.snippetName}
            </Text>
            <Text
              noOfLines={1}
              textStyle='one-line-text-regular-lg'
              color='grey.900'
            >
              {data.snippetAuthor}
            </Text>
          </Flex>
        </Flex>
        <Box
          flex={1}
          maxW='420px'
        >
          {playControls}
        </Box>
        <Flex
          alignItems='center'
          justifyContent='flex-end'
          flex={1}
          marginLeft='auto'
          pr={{ md: '4px' }}
        >
          <IconButton
            colorScheme='transparent'
            color='black'
            aria-label='like'
            onClick={onLike}
          >
            {isLiked ? <IconHeartFill /> : <IconHeartOutline />}
          </IconButton>
          <IconButton
            colorScheme='transparent'
            color='black'
            aria-label='share'
            onClick={onShare}
          >
            <IconShare2 />
          </IconButton>
          {canBuy && (
            <Button
              colorScheme='green'
              ml={5}
              w={180}
              size='lg'
              onClick={onBuy}
            >
              <Text textStyle='heading-large-sm'>
                {t('MiniPlayerDesktop.buyShare')}
              </Text>
            </Button>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
