import { createContext } from 'react';

export type PlayerContextState = {
  audioElement: HTMLAudioElement | null;
};

export type PlayerContextValues = {
  state: PlayerContextState;
};

export const PlayerContext = createContext<PlayerContextValues>({
  state: {
    audioElement: null,
  },
});
